<template>
    <div class="views-list">
        <div @click="select('grid')" :class="['views-list__item',{ _current : modelValue === 'grid' }]"><svg class="icon" xmlns="http://www.w3.org/2000/svg" fill="none">
            <use xlink:href="/static/sprite.svg#sprite-view-grid"></use>
        </svg></div>
        <div @click="select('list')" :class="['views-list__item',{ _current : modelValue === 'list' }]"><svg class="icon" xmlns="http://www.w3.org/2000/svg" fill="none">
            <use xlink:href="/static/sprite.svg#sprite-view-list"></use>
        </svg></div>
    </div>
</template>

<script>
export default {
    name: "ViewsList",
    props: {
        modelValue: {
            type: String,
            default: "grid"
        }
    },
    methods: {
        select(type) {
            this.$emit("update:modelValue",type);
        }
    }
}
</script>

<style scoped>

</style>
