import {apiGet} from "./api";

export function askIndex(url,page,query) {
    return apiGet(url, {p: page,json: 1,...query});
}

export function catalogIndex(regionId,query = null,page = 1) {
    return apiGet(`/region/${regionId}/objects/`,
        Object.assign(
            {p: page,sch: 1,Problem: 0,Secondary: 0},
            query
        )
    );
}

export function searchIndex(regionId,searchString) {
    return apiGet(`${regionId}/searchnameAll/`,{search: searchString});
}
