<template>
    <div>

        <div class="search-filter" v-show="false">
            <collapse-filter ref="filter" @formsubmit="onFormSubmit" :region="region" :filters="filters">
                <template #title>
                    <h1 class="main-title"> <span class="main-title__selected">{{title}}</span></h1>
                </template>
            </collapse-filter>
        </div>


        <div class="row">
            <div class="col-12 mb-3">
                <div class="grid_list_search_result m-0 w-100">
                    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-5">
                        <div class="left_area tac-xsd">
                            <p v-if="!fetching">Показаны {{(currentPage - 1) * 12 + objects.length}} из {{total}}</p>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-8 col-lg-8 col-xl-7">
                        <div class="right_area text-right tac-xsd">
                            <ul>
                                <li class="list-inline-item">
                                    <span class="stts mr-2">Сортировка:</span>
                                    <select v-model="order" class="selectpicker">
                                        <option value="cheap">Сначала дешевле</option>
                                        <option value="expensive">Сначала дороже</option>
                                        <option value="end">По сроку сдачи больше</option>
                                        <option value="endReverse">По сроку сдачи меньше</option>
                                    </select>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <catalog-component :objects="objects" :view-type="viewType" :ads="ads"></catalog-component>
            </div>
        </div>

        <div class="navigation_block">
            <h2 v-if="fetching">
                Подождите, результаты загружаются
            </h2>
            <h2 v-if="!fetching && objects.length === 0">
                Нет результатов
            </h2>
        </div>

        <nav class="navigation_block" v-if="!fetching">
            <div class="mbp_pagination">
                <vue-awesome-paginate
                    :total-items="lastPage"
                    :items-per-page="1"
                    :max-pages-shown="3"
                    v-model="currentPage"
                    paginate-buttons-class="page-link mr-2"
                    active-page-class="active"
                    back-button-class="page-link"
                    next-button-class="page-link"
                    paginationContainerClass="page_navigation justify-content-center"
                >
                    <template #prev-button>
                        <span class="flaticon-left-arrow"></span>
                    </template>
                    <template #next-button>
                        <span class="flaticon-right-arrow"></span>
                    </template>
                </vue-awesome-paginate>
            </div>
        </nav>
    </div>
</template>

<script>
import ViewsList from "../components/ViewsList";
import CatalogComponent from "../components/CatalogComponent";
import {askIndex, catalogIndex} from "../services/catalog";
import CollapseFilter from "../components/forms/CollapseFilter";
import {VueAwesomePaginate} from "vue-awesome-paginate";
import AdvertCarousel from "../components/AdvertCarousel";
import AdvertColumn from "../components/AdvertColumn";

export default {
    name: "CatalogView",
    components: {AdvertCarousel, CollapseFilter, ViewsList, CatalogComponent, VueAwesomePaginate,AdvertColumn},
    props: {
        title: {
            type: String,
            default: 'Новостройки'
        },
        region: {
            type: Number,
            default: 1,
        },
        filters: {
            type: Object
        },
        ads: {
            type: Object,
            required: false,
        },
        url: {
            type: Object,
            required: false,
        },
        source: {
            type: Number,
            default: 2,
        }
    },
    data: ()=>({
        viewType: "grid",
        objects: [],
        currentPage: 1,
        lastPage: 1,
        total: 1,
        order: 'end',
        fetching: false,
        filterQuery: {}
    }),
    computed: {
        orderQuery(){
            const order = {
                'cheap': {
                    order:'PriceTotal',
                    sort:'asc',
                },
                'expensive': {
                    order:'PriceTotal',
                    sort:'desc',
                },
                'end': {
                    order: 'End',
                    sort: 'desc',
                },
                'endReverse': {
                    order: 'End',
                    sort: 'asc',
                },
            }[this.order]

            if (typeof this.ads !== 'undefined') {
                order['perpage'] = this.ads.banner_right ? 11 : 12
            }

            return order;
        }
    },
    methods: {
        onFormSubmit(event,form) {

            const query = {};
            for(let element of event) {
                const key = element[0];
                const value = element[1];
                if(key in query) {
                    const exist = query[key];
                    if(exist instanceof Array) {
                        exist.push(value);
                    }else{
                        query[key] = [query[key],value];
                    }
                }else{
                    query[key] = value;
                }
            }

            this.filterQuery = query;
            this.search();
        },
        async fetchObjects(page = 1) {
            this.fetching = true;

            let response = [];

            if(this.url) {
                response = await askIndex(this.url,page,{
                    ...this.orderQuery
                });
            }else{
                response = await catalogIndex(
                    this.source,
                    {
                        ...this.orderQuery,
                        ...this.filterQuery
                    }, page);
            }
            this.fetching = false;
            return response;
        },
        async search() {
            const response = await this.fetchObjects(this.currentPage);
            this.objects = response.items;
            this.lastPage = response.lastPage;
            this.total = response.total;

            if($("#catalogRoot").length > 0){
                $('html,body').animate({scrollTop:$("#catalogRoot").offset().top - 90}, 500);
            }
        },
    },
    mounted() {
        this.$refs.filter.submit()
    },
    watch: {
        order() {
            this.search();
        },
        currentPage() {
            this.search()
        }
    }
}
</script>

<style scoped>
.navigation_block {
    display: flex;
    justify-content: center;
}
</style>
