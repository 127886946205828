<template>
    <div>
        <h1 class="top-section__title" style="margin-bottom: 55px;">Недвижимость в {{currentRegionNameIn}}</h1>
        <div class="header-location mb-3">
            <div class="header-location__name">Регион:</div>
            <div class="header-location__value">
                <div class="header-location__value-current">
                    <a class="header-location__dropdown-link">{{currentRegion.name}}</a>
                </div>

                <div class="header-location__dropdown">
                    <div class="header-location__dropdown-item" v-for="region of inactiveRegions" :key="region.name">
                        <a class="header-location__dropdown-link" @click="selectRegion(region)" title="">
                            {{region.name}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <filter-form :prevent="false" :region="regionId" @formsubmit="onFormSubmit" :filters="filters"></filter-form>
    </div>
</template>

<script>
import FilterForm from "./forms/FilterForm";

export default {
    name: "FilterComponent",
    components: {
        FilterForm,
    },
    props: {
        filters: Object
    },
    data: () => ({
        rooms: {},
        end: {},
        regionId: 1,
    }),
    computed: {
        currentRegionNameIn() {
            const names = {
                1: "Москве",
                2: "Московской области",
                3: "Новой Москве",
                4: "Санкт-Петербурге"
            }
            return names[this.regionId];
        },
        allRegions() {
            return [
                {name:"Москва",href: '/novostrojki-moskvy/',id: 1},
                {name:"Московская область",href: '/novostrojki-podmoskovya/',id: 2},
                {name:"Новая Москва",href: '/novostrojki-novoj-moskvy/',id: 3},
                {name:"Санкт-Петербург",href: '/novostrojki-sankt-peterburg/',id: 4},
            ]
        },
        currentRegion() {
            return this.allRegions[this.regionId - 1];
        },
        inactiveRegions() {
            return this.allRegions.filter((item,i)=> i+1 !== this.regionId);
        }

    },
    mounted() {
        this.updateObjectsCount();
    },
    methods: {
        onFormSubmit(event) {
            console.log('dedok')
        },
        selectRegion(region) {
            this.regionId = region.id
            this.$el.querySelector("form").action = region.href
        },
        getRegionObjectsCount: async function(regionId,sch) {
            let query = '';
            if(sch) {
                const params = new URLSearchParams();
                params.set('sch','true');
                for(let key in sch) {
                    params.set(key,sch[key]);
                }
                query = `?${params.toString()}`;
            }
            const response = await fetch(`/api/region/${regionId}/objects/count/${query}`);
            const json = await response.json();
            return json;
        },
        updateObjectsCount(){
            $("#submitBtn").text(`Загружаем результаты...`)
            this.getRegionObjectsCount(this.regionId,{}).then(result=>{
                $("#submitBtn").text(`Показать ${result.count}`)
            })
        }
    },
    watch: {
        regionId() {
            this.updateObjectsCount();
        }
    }
}
</script>

<style scoped>

</style>
