<template>
    <div>
        <form ref="form" @submit="submit" id="formFilter" data-new-form action="/novostrojki-moskvy/">
            <input name="sch" value="1" type="hidden">
            <div class="row">

                <div class="col-12 col-md-6 col-lg-6 col-xl-3 mb-4">
                    <div class="check-group check-group_white check-group_no-wrap">
                        <label class="check-group__item" v-for="option in roomsOptions" :key="option.value">
                            <input :value="option.value" :checked="formData.rooms.includes(option.value)" @change="toggleArrayItem(formData.rooms,option.value,$event.target.checked)" name="rooms" type="checkbox">
                            <div class="check-group__label">{{option.brief}}</div>
                        </label>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-6 col-xl-3 mb-4">
                    <div class="check-group check-group_white check-group_no-wrap">

                        <label class="check-group__item">
                            <input type="checkbox" :checked="formData.end.includes('on')" @change="toggleArrayItem(formData.end,'on',$event.target.checked)" name="end">
                            <div class="check-group__label">дом сдан</div>
                        </label>

                        <label class="check-group__item" v-for="select in endFilters" :key="select.value">
                            <input type="checkbox" :checked="formData.end.includes(select.value)" @change="toggleArrayItem(formData.end,select.value,$event.target.checked)" name="end" :value="select.value">
                            <div class="check-group__label">{{ select.name }}</div>
                        </label>
                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-6 col-xl-3 mb-4">

                    <div class="textfield-range textfield-range_white">
                        <input type="hidden" name="PriceTotal">
                        <label class="textfield-range__label" for="price-from">Цена от</label>
                        <input class="textfield-range__field" id="price-from" type="text" name="price-from"
                               placeholder="0.5" :value="(formData.price[0] / 1000000).toFixed()" disabled>
                        <div class="textfield-range__separator">-</div>
                        <label class="textfield-range__label" for="price-to">до</label>
                        <input class="textfield-range__field" id="price-to" type="text" name="price-to" placeholder="12.1"
                               :value="(formData.price[1] / 1000000).toFixed()" disabled>
                        <div class="textfield-range__measure">млн ₽</div>

                        <div class="textfield-range__slider">
                            <slider :lazy="false" :min="3000000" :max="62000000" :tooltips="false" v-model="formData.price"></slider>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-6 col-xl-3 mb-4">

                    <div class="textfield-range textfield-range_white">
                        <input type="hidden" name="square">
                        <label class="textfield-range__label" for="square-from">Площадь от</label>
                        <input :value="formData.square[0]" class="textfield-range__field" id="square-from" type="text" name="square-from" placeholder=""
                               disabled>
                        <div class="textfield-range__separator">-</div>
                        <label class="textfield-range__label" for="square-to">до</label>
                        <input :value="formData.square[1]" class="textfield-range__field" id="square-to" type="text" name="square-to" placeholder=""
                               disabled>
                        <div class="textfield-range__measure">м
                            <span>2</span>
                        </div>
                        <div class="textfield-range__slider">
                            <slider :lazy="false" :min="10.5" :max="120" :tooltips="false" v-model="formData.square"></slider>
                        </div>
                    </div>


                </div>

                <div class="col-sm-12 col-md-5">
                    <div class="textfield-search textfield-search_white">
                        <label class="textfield-search__icon" for="search-field">
                            <svg class="icon" xmlns="http://www.w3.org/2000/svg" fill="none">
                                <use xlink:href="/static/sprite.svg#sprite-search"></use>
                            </svg>
                        </label>
                        <input v-model="searchInput" class="textfield-search__field w-100" id="search-field" type="text" placeholder="ЖК, Метро, Районы">

                        <metro-input v-model="formData.metroIds"></metro-input>

                        <div id="apiSearchResultAll" class="textfield-search__dropdown" v-show="searchInput && searchInput.length > 2 && showResults && searchHints">
                            <template v-if="searchHints && searchHints.objects.length <= 0 && searchHints.stations.length <= 0 && searchHints.areas.length <= 0">
                                <p class="text-center m-0 fs-6">Нет результатов</p>
                            </template>

                            <template v-if="showResults && searchHints">
                                <div v-if="searchHints.objects && searchHints.objects.length > 0">
                                    <div class="mb-3">
                                        <span>ЖК</span>
                                    </div>
                                    <div v-for="hint in searchHints.objects" :key="hint.name"
                                         class="textfield-search__dropdown-item js-searchResultItem d-flex align-items-center">
                                        <input class="me-2"
                                               type="checkbox"
                                               :checked="formData.ids.includes(`${hint.id}.${hint.name}`)"
                                               @input="toggleArrayItem(formData.ids,`${hint.id}.${hint.name}`,$event.target.checked)"
                                               style="cursor: pointer"
                                        >
                                        <a :href="hint.url">{{hint.name}}</a>
                                    </div>
                                    <hr>
                                </div>

                                <div v-if="searchHints.stations && searchHints.stations.length > 0">
                                    <div class="mb-3">
                                        <span>Метро</span>
                                    </div>
                                    <div v-for="hint in searchHints.stations" :key="hint.id"
                                         style="cursor: pointer">
                                        <label class="textfield-search__dropdown-item js-searchResultItem">
                                            <input type="checkbox" :checked="formData.metroIds.includes(+hint.id)" @input="toggleArrayItem(formData.metroIds,hint.id,$event.target.checked)">
                                            {{hint.name}}
                                        </label>
                                    </div>
                                    <hr>
                                </div>

                                <div v-if="searchHints.areas && searchHints.areas.length > 0">
                                    <div class="mb-3">
                                        <span>Районы</span>
                                    </div>
                                    <div v-for="hint in searchHints.areas" :key="hint.ID"
                                         class="textfield-search__dropdown-item js-searchResultItem"
                                         @click="formData.selectedArea = hint.ID;this.showResults = false;searchInput = ''"
                                         style="cursor: pointer">
                                        {{hint.Name}}
                                    </div>
                                    <hr>
                                </div>
                            </template>
                        </div>
                        <input type="hidden" :value="selectedArea ? selectedArea.id : undefined" name="area" id="areaInput">

                        <ul class="custom_select_list" style="display: none;">
                            <li class="custom_select_item">Выберите метро</li>

                            <li class="custom_select_item"
                                v-for="item in metroFilter"
                                :key="item.id"
                                data-type="metro"
                                :data-value="item.id"
                                :label="item.name"
                                :data-line="item.line"
                            >
                                {{item.name}}
                            </li>

                        </ul>
                    </div>
                </div>

                <div class="col-12 col-sm-12 col-lg-7 mb-4">
                    <div class="d-flex justify-content-end">
                        <button class="btn btn_white me-4 w-auto" type="reset" @click="reset()">
                            Очистить
                        </button>
                        <a class="btn-map d-none d-lg-flex btn btn_white me-4 w-auto" href="/новостройки-на-карте" title="На карте">
                            <svg class="icon" xmlns="http://www.w3.org/2000/svg" fill="none">
                                <use xlink:href="/static/sprite.svg#sprite-btn-map-pin"></use>
                            </svg>
                            На карте
                        </a>
                        <button class="btn w-auto" type="submit" name="search" id="submitBtn">Показать новостройки</button>
                    </div>
                </div>

            </div>


            <div class="row">
                <div class="col-12 col-sm-12 col-lg-9 mb-4" style="display: inline-flex;flex-wrap: wrap">
                    <div v-for="metro in checkedMetro" :key="metro.id"
                         :data-line="metro.line"
                         @click="toggleArrayItem(formData.metroIds,metro.id,false)"
                         class="metrnit">{{metro.name}}
                        <input type="hidden" name="metro[]" :value="metro.id">
                    </div>

                    <template v-for="option in roomsOptions" :key="option.value">
                        <div v-if="formData.rooms.includes(option.value)" class="areaItem" @click="toggleArrayItem(formData.rooms,option.value,false)">
                            {{option.label}}
                        </div>
                    </template>

                    <div v-if="formData.end.includes('on')" class="areaItem" @click="toggleArrayItem(formData.end,'on',false)">
                        Дом сдан
                    </div>

                    <template v-for="select in endFilters" :key="select.value">
                        <div class="areaItem" v-if="formData.end.includes(select.value)" @click="toggleArrayItem(formData.end,select.value,false)">
                            Год сдачи: {{select.name}}
                        </div>
                    </template>

                    <div v-if="selectedArea" @click="formData.selectedArea = null" class="areaItem">{{selectedArea.name}}</div>

                    <div v-for="id in formData.ids" class="areaItem" @click="toggleArrayItem(formData.ids,id,false)">
                        <input type="hidden" name="id" :value="id">
                        {{id.replace(".","\n").split("\n")[1]}}
                    </div>

                    <div v-if="priceChanged" class="areaItem" @click="formData.price = [3000000,62000000]">
                        Цена от {{(formData.price[0] / 1000000).toFixed()}} до {{(formData.price[1] / 1000000).toFixed()}} млн ₽
                    </div>

                    <div v-if="squareChanged" class="areaItem" @click="formData.square = [10.5,120]">
                        Площадь от {{formData.square[0]}} до {{formData.square[1]}}
                    </div>
                </div>
            </div>

        </form>
    </div>
</template>

<script>
import {FilterEncoder} from "../../FilterEncoder";
import {searchIndex} from "../../services/catalog";
import MetroInput from "../MetroInput";
import Slider from '@vueform/slider'
import "@vueform/slider/themes/default.css"

export default {
    name: "CollapseFilter",
    components: {
        MetroInput,
        Slider
    },
    props: {
        filters: Object,
        prevent: {
            type: Boolean,
            default: true,
        },
        region: {
            type: Number,
        },
    },
    data: ()=>({
        searchInput: "",
        searchTimeout: 0,
        searchHints: null,
        showResults: false,
        s2ValueSelected: null,
        collapsed: true,
        formData: {
            metroIds: [],
            selectedArea: null,
            rooms: [],
            end: [],
            price: [3000000,62000000],
            square: [10.5,120],
            ids: []
        },
        changed: false,
    }),
    computed: {
        roomsOptions() {
            return [
                {
                    label: 'Студия',
                    brief: 'Студия',
                    value: '6'
                },
                {
                    label: '1 комнатные',
                    brief: '1',
                    value: '1'
                },
                {
                    label: '2 комнатные',
                    brief: '2',
                    value: '2'
                },
                {
                    label: '3 комнатные',
                    brief: '3',
                    value: '3'
                },
                {
                    label: '4 комнатные',
                    brief: '4+',
                    value: '4'
                },
            ]
        },
        endFilters() {
            const currentYear = new Date().getFullYear();
            return this.filters.BottomFilters.end.items.filter(item => currentYear - +item.name <= 0);
        },
        areaFilter() {
            return this.filters.topFilters.area;
        },
        metroFilter() {
            return this.filters.topFilters.metro.items;
        },
        s2Value() {
            if(this.s2ValueSelected) {
                return this.s2ValueSelected;
            }
            if(this.s2ValueSelected === null) {
                return FilterEncoder.s2Value();
            }
            return "";
        },
        totalPrice() {
            return FilterEncoder.totalPrice()
        },
        square() {
            return FilterEncoder.square();
        },
        checkedIds() {
            return FilterEncoder.checkedIds();
        },
        checkedMetro() {
            const ids = this.formData.metroIds
            if(this.metroFilter){
                return this.metroFilter.filter(metro=>ids.includes(+metro.id));
            }
            return [];
        },
        selectedArea() {
            if(this.areaFilter.items) {
                return this.areaFilter.items.find(area=>area.id === this.formData.selectedArea);
            }
            return null;
        },
        priceChanged() {
            return this.formData.price[0] !== 3000000 || this.formData.price[1] !== 62000000;
        },
        squareChanged() {
            return Math.floor(this.formData.square[0]) !== 10 || Math.floor(this.formData.square[1]) !== 120
        }
    },
    watch: {
        searchInput() {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(()=>{
                if(this.searchInput && this.searchInput.length > 2) {
                    this.searchAll();
                    this.showResults = true;
                }
            },1000)
        },
        formData: {
            handler: function(newValue) {
                this.changed = true;
            },
            deep: true
        }
    },
    methods: {
        reset() {
            this.formData = {
                metroIds: [],
                selectedArea: null,
                rooms: [],
                end: [],
                price: [3000000,62000000],
                square: [10.5,120],
                ids: []
            };
        },
        searchAll() {
            searchIndex(this.region,this.searchInput).then(result=>{
                this.searchHints = result;
            });
        },
        isIdChecked(id) {
            return $(`#selectBodyObject [data-id=${id}]`).length > 0;
        },
        toggleArrayItem(array,item,active) {
            const index = array.indexOf(item)
            if(active && index === -1) {
                array.push(item);
            }else if(index !== -1){
                array.splice(index,1)
            }
        },
        /**
         * @param {SubmitEvent} event
         */
        submit(event=null) {
            event && event.preventDefault();

            setTimeout(()=>{
                const target = event ? event.target : this.$refs.form;
                const data = [];
                const selfData = this.formData;

                if(selfData.end.length > 0) {
                    data.push(...selfData.end.map(end=>['end',end]))
                }

                if(selfData.rooms.length > 0) {
                    data.push(...selfData.rooms.map(room=>['rooms',room]))
                }

                if(this.priceChanged) {
                    data.push(['PriceTotal',`-|${selfData.price[0]}|${selfData.price[1]}`])
                }

                if(selfData.metroIds.length > 0) {
                    data.push(...selfData.metroIds.map(id=>['metro',id]))
                }

                if(selfData.ids.length > 0) {
                    data.push(...selfData.ids.map(id=>['id',id]))
                }

                if(selfData.selectedArea) {
                    data.push(['area',selfData.selectedArea])
                }

                if(this.squareChanged) {
                    data.push(['square',`-|${selfData.square[0]}|${selfData.square[1]}`])
                }

                if(!this.prevent){
                    const params = new URLSearchParams();
                    for (const dataItem of data) {
                        params.append(dataItem[0],dataItem[1].toString());
                    }

                    const searchParamsString = params.size > 1 ? '?' + params.toString() : '';
                    window.location.href = `${this.$refs.form.action}${searchParamsString}`;
                }else{
                    console.log(data)
                    this.$emit('formsubmit',data,target);
                }
            },100)

            this.changed = false;
            return false;
        }
    },
    mounted() {
        // this.searchInput = this.s2Value;

        this.formData.selectedArea = +FilterEncoder.area();
        this.formData.metroIds = FilterEncoder.metro();
        this.formData.rooms = FilterEncoder.checkedRooms();
        this.formData.end = FilterEncoder.checkedEnd();
        this.formData.ids = FilterEncoder.checkedIds();
        this.formData.price = FilterEncoder.totalPrice();
        this.formData.square = FilterEncoder.square();

        $(document).click((e)=>{
            const textField = e.target.closest(".textfield-search");

            this.showResults = !!textField;
        })

        this.$nextTick(()=>{
            this.changed = false;
        })
    }
}
</script>

<style scoped>
.btn[name="search"][disabled] {
    background-color: var(--light-color);
    border-color: var(--light-color);
    color: var(--main-color);
    cursor: default;
}
</style>
