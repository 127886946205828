<template>
    <div class="textfield-search__btn" @click="openPopup">
        Метро
    </div>
</template>

<script>
export default {
    name: "MetroInput",
    props: {
        modelValue: {
            type: Array,
            required: true
        }
    },
    methods: {
        toggleMetro(metro) {
            const index = this.modelValue.indexOf(metro.id)
            if(index === -1) {
                const copy = this.modelValue.slice()
                copy.push(metro.id)
                this.$emit("update:modelValue",copy)
            }else{
                const copy = this.modelValue.slice()
                copy.splice(index,1)
                this.$emit("update:modelValue",copy)
            }
        },
        openPopup() {
            const callback = this.toggleMetro.bind(this);
            $.fancybox.open('<div id="metro_big" style="width: 1251px; display: inline-block;" >' + '<div class="metroBigHeader">' + '<h2>Поиск по метро</h2>' + '<button class="btn btn--red btn_sibmit okmetro">Сохранить</button>' + '</div>' + '<div  id="filtersMetro"></div>' + '</div>', {
                afterShow: function(instance, current) {
                    addSchemeFilter(callback)
                },
                touch: false,
                afterClose: function(instance, current) {
                    $.fancybox.destroy();
                }
            });
        }
    }
}
</script>

<style scoped>

</style>
