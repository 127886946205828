<template>
    <div>
        <div class="products-list">

            <div class="row">
                <template v-for="(object,index) in allObjects" :key="object.id">
                    <div class="col-12" v-if="index > 0 && (ads.banner_right && index % 8 === 0 || !ads.banner_right && index % 9 === 0) ">
                        <div class="full-width">
                            <div class="maxw1600 m0a">
                                <advert-carousel :ads="ads"></advert-carousel>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-4 mb-4" v-if="index === 2 && ads.banner_right">
                        <a :href="ads.banner_right.link">
                            <div class="adv-banner-right">
                                <div class="adv-banner-right__img d-flex align-items-center">
                                    <img class="img-fluid w100 h-100" style="object-fit: contain;border-radius: 8px" :src="'/'+ads.banner_right.image" :alt="ads.banner_right.title">
                                </div>

                                <!--<div class="advert-item__info-tooltip" data-adv-tooltip>
                                    <p>{{advert.text}}</p>
                                    <a v-if="advert.phone" :href="'tel:'+advert.phone">{{advert.phone.slice(0,-5).concat("XX-XX")}}</a>
                                    <a :href="advert.link" target="_blank" rel="nofollow">{{advert.clear_url}}</a>
                                    <span v-if="advert.external_id" class="advert-id small">
                                        {{advert.external_id}}
                                    </span>
                                    <div class="advert-item__close-tooltip" data-adv-show-tooltip>
                                        <i class="flaticon-close"></i>
                                    </div>
                                </div>-->
                            </div>
                        </a>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-4 mb-4">
                        <div :class="['feat_property home7 style4',{'feat_property__colored': object.colored,'feat_property__boosted': object.boosted}]">

                            <div class="thumb">
                                <div class="fp_single_item_slider">
                                    <div class="item">
                                        <img v-if="object.imageFull" class="products-item__img" :src="('/'+object.imageFull).replaceAll('//','/')" role="presentation"
                                             style="" :alt="object.name">
                                        <img v-else class="products-item__img" src="/static/icons/ph.png" role="presentation" style="" :alt="object.name">
                                    </div>
                                    <template v-for="i in 2" :key="i">
                                        <div class="item">
                                            <img v-if="object.images[i]" :src="('/'+object.images[i]).replaceAll('//','/')" :alt="object.name">
                                        </div>
                                    </template>
                                </div>
                                <div class="thmb_cntnt style2">
                                    <ul class="tag mb0" v-if="object.colored || object.boosted">
                                        <li class="list-inline-item" v-if="object.colored">Популярное</li>
                                        <li class="list-inline-item" v-if="object.boosted">Рекомендуем</li>
                                    </ul>
                                </div>
                                <div class="thmb_cntnt style3">
                                    <a class="fp_price">
                                        <template v-if="object.PriceTotal">
                                            {{ object.PriceTotal }}
                                        </template>
                                        <template v-else>
                                            Продажи на первичном рынке завершены
                                        </template>
                                    </a>
                                </div>
                            </div>
                            <div class="details">
                                <div  @click="openPage(object.url)" class="tc_content h-100 d-flex flex-column justify-content-between">
                                    <div>
                                        <p class="text-thm">Сдача {{object.end}}</p>
                                        <h4>{{ object.name }}</h4>
                                        <p v-if="object.metro"><span class="flaticon-placeholder"></span> {{object.metro}}</p>
                                        <ul class="prop_details mb0">
                                            <template v-for="price in object.prices">
                                                <li class="list-inline-item mb-2" v-if="price.MinPrice">
                                                    <a>{{ price.name }} от {{ price.MinSquare }}
                                                        м²</a>
                                                    <a>от {{ price.MinPrice }}
                                                        <template v-if="price.MaxPrice">до {{ price.MaxPrice }}</template>
                                                        млн ₽</a>
                                                </li>
                                            </template>
                                        </ul>
                                    </div>
                                    <div v-if="object.developer">
                                        Застройщик: <span v-html="object.developer.Name"></span>
                                    </div>
                                </div>
                                <div class="fp_footer">
                                    <ul class="fp_phone float-left mb0">
                                        <li class="list-inline-item">
                                            <a data-v-0df5271d="" class="js-phone_btn" :data-full-phone="object.phone" href="#">{{object.phone.slice(0,-5).concat("XX-XX")}}</a>
                                        </li>
                                    </ul>
                                    <div class="float-right"><a :href="object.url">Подробнее</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

                <div class="col-12" v-if="ads.banner_right && allObjects.length < 3">
                    <div class="full-width">
                        <div class="maxw1600 m0a">
                            <advert-carousel :ads="ads"></advert-carousel>
                        </div>
                    </div>
                </div>
                <div class="col-12" v-if="!ads.banner_right && allObjects.length < 9">
                    <div class="full-width">
                        <div class="maxw1600 m0a">
                            <advert-carousel :ads="ads"></advert-carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import AdvertCarousel from "./AdvertCarousel";
export default {
    name: "CatalogComponent",
    components: {AdvertCarousel},
    props: {
        objects: Object,
        ads: {
            type: Object,
            default: []
        }
    },
    computed: {
        allObjects() {
            return Object.values(this.objects);
        }
    },
    methods: {
        openPage(url) {
            window.location.href = (url);
        }
    },
    updated() {
        $('.fp_single_item_slider').owlCarousel({
            loop:true,
            margin:15,
            dots: false,
            nav:true,
            rtl:false,
            autoplayHoverPause:false,
            autoplay: false,
            smartSpeed: 2000,
            singleItem: true,
            navText: [
                '<i class="flaticon-left-arrow-1"></i>',
                '<i class="flaticon-right-arrow"></i>'
            ],
            responsive: {
                320:{
                    items: 1,
                    center: false
                },
                480:{
                    items: 1,
                    center: false
                },
                600: {
                    items: 1,
                    center: false
                },
                768: {
                    items: 1
                },
                992: {
                    items: 1
                },
                1200: {
                    items: 1
                }
            }
        })
    }
}
</script>

<style scoped>
.feat_property {
    height: 100%;
    display: grid;
    grid-template-rows: 0.5fr 1fr;
}
.feat_property .thumb {
    height: max-content;
}
.feat_property .thumb img {
    aspect-ratioo: 3/2;
}
.feat_property .details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.fp_phone a {
    font-size: 14px;
    font-family: "Nunito";
    color: #777777;
    line-height: 1.2;
}
</style>
