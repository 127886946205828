<template>
    <div>
        <div v-if="ads.banner_right">
            <div class="d-flex col-12">
                <a class="section-block section-block_gray" href="{{ads.banner_righ.link}}"
                   style="text-align: center">
                    <img class="m-auto" src="/{{ads.banner_righ.image}}" :alt="ads.title">
                </a>
            </div>
        </div>
        <h4>Популярные новостройки</h4>
        <div v-for="advert in allTgb" :key="advert.id" class="position-relative advert-item__wrapper">
            <div class="advert-item">
                <div class="advert-item__img-wrap">
                    <img class="advert-item__img" :src="'/'+advert.image" :alt="advert.title">
                </div>
                <div class="advert-item__content">
                    <div class="row g-2">
                        <div class="col-12">
                            <h3 class="advert-item__title">{{advert.title}}</h3>
                        </div>
                        <div class="col-12">
                            <p class="advert-item__description">{{advert.text}}</p>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="advert-item__phone" v-if="advert.phone" >
                                <a class="js-phone_btn" :data-full-phone="advert.phone" href="#">{{advert.phone.slice(0,-5).concat("XX-XX")}}</a>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="advert-item__site">
                                <a :href="advert.link" target="_blank" rel="nofollow">
                                    Перейти на сайт
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="advert-item__info" data-adv-show-tooltip>
                <img src="/static/imgNewTemplate/info.svg" alt="info">
            </div>

            <div class="advert-item__info-tooltip" data-adv-tooltip>
                <p>{{advert.text}}</p>
                <a v-if="advert.phone" :href="'tel:'+advert.phone">{{advert.phone.slice(0,-5).concat("XX-XX")}}</a>
                <a :href="advert.link" target="_blank" rel="nofollow">{{advert.clear_url}}</a>
                <span v-if="advert.external_id" class="advert-id small">
                        {{advert.external_id}}
                    </span>
                <div class="advert-item__close-tooltip" data-adv-show-tooltip>
                    <i class="flaticon-close"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AdvertColumn",
    props: {
        ads: {
            type: Array,
            required: true,
        }
    },
    computed: {
        allTgb() {
            return this.ads.standart[0].slice(0,5);
        }
    }
}
</script>

<style scoped>

</style>
