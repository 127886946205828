window.Vue = require("vue");
const vueAwesomePaginate = require("vue-awesome-paginate");
import "vue-awesome-paginate/dist/style.css";

window.mountFilterComponent = function (rootContainer,props = null) {
    const app = Vue.createApp(require("./components/FilterComponent.vue").default,props);
    app.mount(rootContainer);
}

window.mountCatalogComponent = function(rootContainer,props = null) {
    const app = Vue.createApp(require("./view/CatalogView.vue").default,props);
    app.use(vueAwesomePaginate);
    app.mount(rootContainer);
}

window.mountCollapseFilterComponent = function(rootContainer,props = null) {
    const app = Vue.createApp(require("./components/forms/CollapseFilter.vue").default,props);
    app.use(vueAwesomePaginate);
    app.mount(rootContainer);
}

