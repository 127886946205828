<template>
    <div class="search-ads mb-5 team_slider advert-carousel position-relative">
        <div class="item" v-for="advert in allTgb" :key="advert.id">
            <div class="for_blog feat_property feat_advert tall">
                <div class="thumb">
                    <a :href="advert.link" target="_blank" class="img-whp">
                        <img style="max-height: 172px" :src="'/'+advert.image" :alt="advert.title">
                    </a>
                    <div class="advert-item__info" data-adv-show-tooltip>
                        <img src="/static/imgNewTemplate/info.svg" alt="">
                    </div>
                </div>
                <div class="details">
                    <div class="tc_content h-100">
                        <a :href="advert.link" target="_blank" class="h-100 d-block">
                            <p class="text-thm">Спецпредложение</p>
                            <h4>{{ advert.title }}</h4>
                            <p>{{ advert.text }}</p>
                        </a>
                    </div>
                    <div class="fp_footer">
                        <div class="row">
                            <div class="col-lg-6 col-md-12 pr-lg-1">
                                <div class="advert-item__phone" v-if="advert.phone">
                                    <a class="js-phone_btn" :data-full-phone="advert.phone"
                                       href="#">{{ advert.phone.slice(0, -5).concat("XX-XX") }}</a>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 pl-lg-1 mt-2 mt-lg-0">
                                <div class="advert-item__site">
                                    <a :href="advert.link" target="_blank" rel="nofollow">
                                        Перейти на сайт
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="advert-item__info-tooltip" data-adv-tooltip>
                    <p>{{ advert.text }}</p>
                    <div v-if="advert.phone">
                        <a class="js-phone_btn" :data-full-phone="advert.phone"
                           href="#">{{ advert.phone.slice(0, -5).concat("XX-XX") }}</a>
                    </div>
                    <a :href="advert.link" target="_blank" rel="nofollow">{{ advert.clear_url }}</a>
                    <span v-if="advert.external_id" class="advert-id small">
                        {{ advert.external_id }}
                    </span>
                    <div class="advert-item__close-tooltip" data-adv-show-tooltip>
                        <i class="flaticon-close"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AdvertCarousel",
    props: {
        ads: {
            type: Object,
            required: true,
        }
    },
    computed: {
        allTgb() {
            if (this.ads.standart[0].length > 0) {
                return this.ads.standart[0].slice(0, 30);
            }
            /*return this.ads.standart[0].slice(0, 5);*/
        }
    },
    mounted() {
        $('.advert-carousel').owlCarousel({
            loop: true,
            margin: 30,
            dots: false,
            nav: true,
            rtl: false,
            autoplayHoverPause: false,
            autoplay: false,
            singleItem: true,
            smartSpeed: 1200,
            navText: [
                '<i class="flaticon-left-arrow"></i>',
                '<i class="flaticon-right-arrow"></i>'
            ],
            responsive: {
                0: {
                    items: 1,
                    center: false
                },
                480: {
                    items: 1,
                    center: false
                },
                520: {
                    items: 2,
                    center: false
                },
                600: {
                    items: 2,
                    center: false
                },
                768: {
                    items: 2
                },
                992: {
                    items: 3
                },
                1200: {
                    items: 4
                },
                1366: {
                    items: 4
                },
                1400: {
                    items: 4
                }
            }
        })
    }
}
</script>

<style scoped>
.search-ads-item img {
    max-height: 230px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.search-ads-item {
    height: 100%;
}
</style>
