export const FilterEncoder = {
    query() {
        return new URLSearchParams(window.location.search);
    },
    checkedEnd() {
        return this.query().getAll("end").map(end=>isNaN(end) ? end : +end)
    },
    checkedRooms() {
        return this.query().getAll('rooms')
    },
    s2Value() {
        return this.query().get('s2')
    },
    totalPrice(maxPrice = 62000000) {
        const priceTotal = this.query().get('PriceTotal');
        if(priceTotal) {
            return priceTotal.replace('-','')
                .split('|').filter(item=>item)
                .map(item=>item)
        }

        return [3000000,maxPrice];
    },
    square() {
        const priceTotal = this.query().get('square');
        if(priceTotal) {
            return priceTotal.replace('-','')
                .split('|').filter(item=>item)
        }

        return [10.5,120];
    },
    checkedIds() {
        return this.query().getAll('id');
    },
    metro() {
        return this.query().getAll("metro");
    },
    area() {
        return this.query().get('area');
    },
    reconstruction() {
        return this.query().get('otdelka')?.split('|') || []
    },
    class() {
        return this.query().get('class')?.split('|') || []
    },
    timeFoot() {
        return this.query().get('TimeFoot')
    }

}
