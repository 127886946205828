<template>
    <div>
        <form ref="form" @submit="submit" id="formFilter" data-new-form :action="currentRegion.href">
            <div class="tab-content home1_adsrchfrm" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                     aria-labelledby="pills-home-tab">
                    <div class="home1-advnc-search home6">
                        <div class="row h1ads_1st_list">
                            <div class="col-12">
                                <div class="row gy-1 g-3">
                                    <div class="col-12 col-md-6 col-lg-8 col-xxl-4">
                                        <div class="dropdown">
                                            <input v-model="searchInput" class="form-control search-name-input"
                                                   type="text" placeholder="Введите название, место, ЖК, метро">
                                            <div
                                                :class="['dropdown-menu p-3 w-100',{'show': searchInput && searchInput.length > 2 && showResults && searchHints}]">
                                                <template
                                                    v-if="searchHints && searchHints.objects.length <= 0 && searchHints.stations.length <= 0 && searchHints.areas.length <= 0">
                                                    <p class="text-center m-0 fs-6">Нет результатов</p>
                                                </template>

                                                <template v-if="showResults && searchHints">
                                                    <div v-if="searchHints.objects && searchHints.objects.length > 0">
                                                        <div class="mb-3">
                                                            <span>ЖК</span>
                                                        </div>
                                                        <div v-for="hint in searchHints.objects" :key="hint.name"
                                                             class="textfield-search__dropdown-item js-searchResultItem d-flex align-items-center">
                                                            <input class="me-2"
                                                                   type="checkbox"
                                                                   :checked="formData.ids.includes(`${hint.id}.${hint.name}`)"
                                                                   @input="toggleArrayItem(formData.ids,`${hint.id}.${hint.name}`,$event.target.checked,true)"
                                                                   style="cursor: pointer"
                                                            >
                                                            <a class="ml-2" :href="hint.url">{{ hint.name }}</a>
                                                        </div>
                                                        <hr>
                                                    </div>

                                                    <div v-if="searchHints.stations && searchHints.stations.length > 0">
                                                        <div class="mb-3">
                                                            <span>Метро</span>
                                                        </div>
                                                        <div v-for="hint in searchHints.stations" :key="hint.id"
                                                             style="cursor: pointer">
                                                            <label
                                                                class="textfield-search__dropdown-item js-searchResultItem">
                                                                <input type="checkbox"
                                                                       class="me-2"
                                                                       :checked="formData.metroIds.includes(+hint.id)"
                                                                       @input="toggleArrayItem(formData.metroIds,hint.id,$event.target.checked,true)">
                                                                <a class="ml-2" :href="hint.FullLink">{{ hint.name }}</a>
                                                            </label>
                                                        </div>
                                                        <hr>
                                                    </div>

                                                    <div v-if="searchHints.areas && searchHints.areas.length > 0">
                                                        <div class="mb-3">
                                                            <span>Районы</span>
                                                        </div>
                                                        <div v-for="hint in searchHints.areas" :key="hint.ID"
                                                             class="textfield-search__dropdown-item js-searchResultItem">
                                                            <input type="checkbox"
                                                                   class="me-2"
                                                                   :checked="formData.selectedArea === hint.ID"
                                                                   @input="formData.selectedArea = $event.target.checked ? hint.ID : null">
                                                            <a class="ml-2" :href="hint.FullLink">{{ hint.Name }}</a>
                                                        </div>
                                                        <hr>
                                                    </div>
                                                </template>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6 col-lg-4 col-xxl-2">
                                        <div class="region-select-dropdown">
                                            <select v-model="region"
                                                    class="selectpicker w-100">
                                                <option v-for="region in allRegions" :value="region.id" :key="region.id">
                                                    {{ region.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-2 col-lg-4 col-xxl-2">
                                        <a href="/новостройки-на-карте/" class="btn-primary-opaque btn w-100 h-100 flex-align-center">
                                            На карте
                                        </a>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4 col-xxl-2 flex-align-center">
                                        <span @click="collapsed = !collapsed" class="dropbtn">Подбор по параметрам <i
                                            class="flaticon-more pl10 flr-520"></i>
                                    </span>
                                    </div>
                                    <div class="col-12 col-md-6 col-lg-4 col-xxl-2">
                                        <div class="h-100">
                                            <button type="submit" class="btn search-btn h-100 w-100" :disabled="!canSearch">
                                                Поиск
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mega-dropdown home6">
                            <div class="dropdown-content"
                                 :style="{'display': !collapsed ? 'block' : undefined}">
                                <div class="row g-3 mb-2">
                                    <div class="col-sm-12 col-md-6 col-lg-3">
                                        <div class="search_option_two">
                                            <div class="candidate_revew_select">
                                                <select v-model="formData.rooms" multiple
                                                        class="selectpicker show-tick w-100"
                                                        data-title="Кол-во комнат"
                                                        data-selected-text-format="static"
                                                >
                                                    <option v-for="option in roomsOptions"
                                                            :key="option.value" :value="option.value">
                                                        {{ option.brief }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-3">
                                        <div class="search_option_two">
                                            <div class="candidate_revew_select">
                                                <select v-model="formData.end" multiple
                                                        class="selectpicker show-tick w-100"
                                                        data-title="Год сдачи"
                                                        data-selected-text-format="static"
                                                >
                                                    <option v-for="select in endFilters"
                                                            :key="select.value"
                                                            :value="select.value"
                                                    >
                                                        {{ select.name }}
                                                    </option>
                                                    <option value="on">дом сдан</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-3">
                                        <div class="btn w-100 mr-2">
                                            Цена:
                                            от {{ (formData.price[0] / 1000000).toFixed() }}
                                            до {{ (formData.price[1] / 1000000).toFixed() }}
                                            млн ₽
                                        </div>

                                        <slider class="px-2" :lazy="false" :min="3000000" :max="filters.BottomFilters.price.max"
                                                :tooltips="false" v-model="formData.price"></slider>
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-3">
                                        <div class="btn w-100 mr-2">
                                            Площадь:
                                            {{ formData.square[0] }} -
                                            {{ formData.square[1] }}
                                            м²
                                        </div>
                                        <slider class="px-2" :lazy="false" :min="10.5" :max="120" :tooltips="false"
                                                v-model="formData.square"></slider>
                                    </div>
                                    <div class="col-12 col-md-6 col-lg-3">
                                        <div class="search_option_two">
                                            <div class="candidate_revew_select">
                                                <select v-model="formData.class" multiple
                                                        class="selectpicker show-tick w-100"
                                                        data-title="Класс"
                                                        data-selected-text-format="static"
                                                >
                                                    <option v-for="select in classOptions"
                                                            :key="select.value"
                                                            :value="select.value"
                                                    >
                                                        {{ select.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6 col-lg-3">
                                        <div class="search_option_two">
                                            <div class="candidate_revew_select">
                                                <select v-model="formData.timeFoot"
                                                        class="selectpicker show-tick w-100"
                                                        data-title="До метро пешком"
                                                >
                                                    <option v-for="select in timeFootOption"
                                                            :key="select.value"
                                                            :value="select.value"
                                                    >
                                                        {{ select.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6 col-lg-3">
                                        <div class="search_option_two">
                                            <div class="candidate_revew_select">
                                                <select v-model="formData.reconstruction" multiple
                                                        class="selectpicker show-tick w-100"
                                                        data-title="Отделка"
                                                        data-selected-text-format="static"
                                                >
                                                    <option v-for="select in reconstructionOptions"
                                                            :key="select.value"
                                                            :value="select.value"
                                                    >
                                                        {{ select.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-lg-9"
                                         style="display: inline-flex;flex-wrap: wrap">
                                        <div v-for="metro in checkedMetro" :key="metro.id"
                                             :data-line="metro.line"
                                             @click="toggleArrayItem(formData.metroIds,metro.id,false)"
                                             class="metro-label">{{ metro.name }}
                                            <input type="hidden" name="metro[]" :value="metro.id">
                                        </div>

                                        <template v-for="option in roomsOptions" :key="option.value">
                                            <div v-if="formData.rooms.includes(option.value)"
                                                 class="areaItem"
                                                 @click="toggleArrayItem(formData.rooms,option.value,false)">
                                                {{ option.label }}
                                            </div>
                                        </template>

                                        <div v-if="formData.end.includes('on')" class="areaItem"
                                             @click="toggleArrayItem(formData.end,'on',false)">
                                            Дом сдан
                                        </div>

                                        <template v-for="select in endFilters" :key="select.value">
                                            <div class="areaItem" v-if="formData.end.includes(select.value)"
                                                 @click="toggleArrayItem(formData.end,select.value,false)">
                                                Год сдачи: {{ select.name }}
                                            </div>
                                        </template>

                                        <template v-for="select in classOptions" :key="select.value">
                                            <div class="areaItem" v-if="formData.class.includes(select.value)"
                                                 @click="toggleArrayItem(formData.class,select.value,false)">
                                                {{ select.name }}
                                            </div>
                                        </template>

                                        <template v-for="select in reconstructionOptions" :key="select.value">
                                            <div class="areaItem" v-if="formData.reconstruction.includes(select.value)"
                                                 @click="toggleArrayItem(formData.reconstruction,select.value,false)">
                                                {{ select.name }}
                                            </div>
                                        </template>

                                        <div v-if="formData.timeFoot" @click="formData.timeFoot = null"
                                             class="areaItem">
                                            до метро: {{ timeFootOption.find(opt=>opt.value === formData.timeFoot).name }}
                                        </div>

                                        <div v-if="selectedArea" @click="formData.selectedArea = null"
                                             class="areaItem">
                                            {{ selectedArea.name }}
                                        </div>

                                        <div v-for="id in formData.ids" class="areaItem"
                                             @click="toggleArrayItem(formData.ids,id,false)">
                                            <input type="hidden" name="id" :value="id">
                                            {{ id.replace(".", "\n").split("\n")[1] }}
                                        </div>

                                        <div v-if="priceChanged" class="areaItem"
                                             @click="formData.price = [3000000,filters.BottomFilters.price.max]">
                                            Цена от {{ (formData.price[0] / 1000000).toFixed() }} до
                                            {{ (formData.price[1] / 1000000).toFixed() }} млн ₽
                                        </div>

                                        <div v-if="squareChanged" class="areaItem"
                                             @click="formData.square = [10.5,120]">
                                            Площадь от {{ formData.square[0] }} до {{ formData.square[1] }}
                                        </div>
                                    </div>
                                    <div class="col-12 d-block d-md-none">
                                        <button type="submit" class="btn search-btn h-100 w-100" :disabled="!canSearch">
                                            Поиск
                                        </button>
                                    </div>
                                    <div class="col-12 text-right">
                                        <span class="curp color-primary" @click="collapsed = true">Закрыть</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <input name="sch" value="1" type="hidden">
        </form>
    </div>
</template>

<script>
import {FilterEncoder} from "../../FilterEncoder";
import {searchIndex} from "../../services/catalog";
import MetroInput from "../MetroInput";
import Slider from '@vueform/slider'
import "@vueform/slider/themes/default.css"

export default {
    name: "CollapseFilter",
    components: {
        MetroInput,
        Slider
    },
    props: {
        filters: Object,
        prevent: {
            type: Boolean,
            default: true,
        },
        initialRegion: {
            default: 0,
        }
    },
    data: () => ({
        searchInput: "",
        searchTimeout: 0,
        searchHints: null,
        showResults: false,
        s2ValueSelected: null,
        collapsed: true,
        region: 0,
        formData: {
            metroIds: [],
            selectedArea: null,
            rooms: [],
            end: [],
            price: [3000000, 62000000],
            square: [10.5, 120],
            ids: [],
            class: [],
            timeFoot: null,
            reconstruction: [],
        },
        changed: false,
    }),
    computed: {
        canSearch() {
          return this.region !== 0
        },
        roomsOptions() {
            return [
                {
                    label: 'Студия',
                    brief: 'Студия',
                    value: '6'
                },
                {
                    label: '1 комнатные',
                    brief: '1',
                    value: '1'
                },
                {
                    label: '2 комнатные',
                    brief: '2',
                    value: '2'
                },
                {
                    label: '3 комнатные',
                    brief: '3',
                    value: '3'
                },
                {
                    label: '4 комнатные',
                    brief: '4+',
                    value: '4'
                },
            ]
        },
        classOptions() {
            return [
                {
                    'name': 'Эконом',
                    'value': '1'
                },
                {
                    'name': 'Комфорт',
                    'value': '2'
                },
                {
                    'name': 'Бизнес',
                    'value': '3'
                },
                {
                    'name': 'Премиум',
                    'value': '4'
                },
                {
                    'name': 'Элит',
                    'value': '5'
                },
            ];
        },
        timeFootOption() {
            return [
                {
                    'name': 'до 5 минут',
                    'value': '<5'
                },
                {
                    'name': 'до 15 минут',
                    'value': '<15'
                },
                {
                    'name': 'до 20 минут',
                    'value': '<20'
                },
                {
                    'name': 'от 20 минут',
                    'value': '>20'
                }
            ]
        },
        reconstructionOptions() {
          return this.filters.BottomFilters.reconstruction.items
        },
        allRegions() {
            return [
                {name: "Выберите регион", href: '/novostrojki-moskvy/', id: 0},
                {name: "Москва", href: '/novostrojki-moskvy/', id: 1},
                {name: "Московская область", href: '/novostrojki-podmoskovya/', id: 2},
                {name: "Новая Москва", href: '/novostrojki-novoj-moskvy/', id: 3},
                {name: "Санкт-Петербург", href: '/novostrojki-sankt-peterburg/', id: 4},
            ]
        },
        currentRegion() {
            return this.allRegions.find(region=>region.id === this.region) || this.allRegions[0]
        },
        endFilters() {
            const currentYear = new Date().getFullYear();
            return this.filters.BottomFilters.end.items.filter(item => currentYear - +item.name <= 0);
        },
        areaFilter() {
            return this.filters.topFilters.area;
        },
        metroFilter() {
            return this.filters.topFilters.metro.items;
        },
        s2Value() {
            if (this.s2ValueSelected) {
                return this.s2ValueSelected;
            }
            if (this.s2ValueSelected === null) {
                return FilterEncoder.s2Value();
            }
            return "";
        },
        totalPrice() {
            return FilterEncoder.totalPrice()
        },
        square() {
            return FilterEncoder.square();
        },
        checkedIds() {
            return FilterEncoder.checkedIds();
        },
        checkedMetro() {
            const ids = this.formData.metroIds
            if (this.metroFilter) {
                return this.metroFilter.filter(metro => ids.includes(metro.id));
            }
            return [];
        },
        selectedArea() {
            if (this.areaFilter.items) {
                return this.areaFilter.items.find(area => area.id === this.formData.selectedArea);
            }
            return null;
        },
        priceChanged() {
            return this.formData.price[0] !== 3000000 || this.formData.price[1] !== this.filters.BottomFilters.price.max;
        },
        squareChanged() {
            return Math.floor(this.formData.square[0]) !== 10 || Math.floor(this.formData.square[1]) !== 120
        },
        hasTitle() {
            return 'title' in this.$slots;
        }
    },
    watch: {
        region() {
            this.formData.metroIds = []
            this.formData.selectedArea = null
            this.formData.ids = []
            this.searchHints = null
        },
        searchInput() {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                if (this.searchInput && this.searchInput.length > 2) {
                    this.searchAll();
                    this.showResults = true;
                }
            }, 200)
        },
        formData: {
            handler: function (newValue) {
                this.changed = true;
            },
            deep: true
        }
    },
    methods: {
        reset() {
            this.formData = {
                metroIds: [],
                selectedArea: null,
                rooms: [],
                end: [],
                price: [3000000, this.filters.BottomFilters.price.max],
                square: [10.5, 120],
                ids: []
            };
        },
        searchAll() {
            searchIndex(this.region || 1, this.searchInput).then(result => {
                this.searchHints = result;
            });
        },
        isIdChecked(id) {
            return $(`#selectBodyObject [data-id=${id}]`).length > 0;
        },
        toggleArrayItem(array, item, active,removeOthers = false) {
            const index = array.indexOf(item)

            if(removeOthers) {
                array.splice(0,array.length)

                if(active) {
                    array.push(item);
                }
                return
            }

            if (active && index === -1) {
                array.push(item);
            } else if (index !== -1) {
                array.splice(index, 1)
            }
        },
        showPriceModal() {
            $('.dd_content2').toggle()
        },
        /**
         * @param {SubmitEvent} event
         */
        submit(event = null) {
            event && event.preventDefault();

            setTimeout(() => {
                const target = event ? event.target : this.$refs.form;
                const data = [];
                const selfData = this.formData;

                if (selfData.end.length > 0) {
                    data.push(...selfData.end.map(end => ['end', end]))
                }

                if (selfData.rooms.length > 0) {
                    data.push(...selfData.rooms.map(room => ['rooms', room]))
                }

                if (this.priceChanged) {
                    data.push(['PriceTotal', `-|${selfData.price[0]}|${selfData.price[1]}`])
                }

                if (selfData.metroIds.length > 0) {
                    data.push(...selfData.metroIds.map(id => ['metro', id]))
                }

                if (selfData.ids.length > 0) {
                    data.push(...selfData.ids.map(id => ['id', id]))
                }

                if (selfData.selectedArea) {
                    data.push(['area', selfData.selectedArea])
                }

                if(selfData.reconstruction.length > 0) {
                    data.push(['otdelka',selfData.reconstruction.join('|')])
                }

                if(selfData.class.length > 0) {
                    data.push(['class',selfData.class.join('|')])
                }

                if(selfData.timeFoot !== null) {
                    data.push(['TimeFoot',selfData.timeFoot])
                }

                if (this.squareChanged) {
                    data.push(['square', `-|${selfData.square[0]}|${selfData.square[1]}`])
                }

                if (!this.prevent) {
                    const params = new URLSearchParams();
                    for (const dataItem of data) {
                        params.append(dataItem[0], dataItem[1].toString());
                    }
                    params.set('sch', '1');

                    const searchParamsString = params.size > 1 ? '?' + params.toString() : '';
                    window.location.href = `${this.$refs.form.action}${searchParamsString}`;
                } else {
                    console.log(data)
                    this.$emit('formsubmit', data, target);
                }
            }, 100)

            this.changed = false;
            return false;
        }
    },
    mounted() {
        this.reset();
        this.region = this.initialRegion;
        this.formData.selectedArea = +FilterEncoder.area();
        this.formData.metroIds = FilterEncoder.metro();
        this.formData.rooms = FilterEncoder.checkedRooms();
        this.formData.end = FilterEncoder.checkedEnd();
        this.formData.ids = FilterEncoder.checkedIds();
        this.formData.price = FilterEncoder.totalPrice(this.filters.BottomFilters.price.max);
        this.formData.square = FilterEncoder.square();
        this.formData.class = FilterEncoder.class();
        this.formData.timeFoot = FilterEncoder.timeFoot();
        this.formData.reconstruction = FilterEncoder.reconstruction();

        document.addEventListener('click', (e) => {
            const textField = e.target.closest(".dropdown");

            this.showResults = !!textField;
            if(!e.target.closest('.home1-advnc-search') && e.target.parentNode !== null) {
                this.collapsed = true
            }
        })

        this.$nextTick(() => {
            this.changed = false;
        })
    }
}
</script>

<style scoped>
.btn[name="search"][disabled] {
    background-color: var(--light-color);
    border-color: var(--light-color);
    color: var(--main-color);
    cursor: default;
}

@media screen and (max-width: 769px) {
    div.collapse-height {
        grid-template-rows: 1fr;
    }

    button[type=reset] {
        display: none;
    }
}

.areaItem {
    position: relative;
    display: inline-block;
    background-color: rgb(242, 242, 242);
    margin-right: 2px;
    cursor: pointer;
    font-size: 11px;
    padding: 8px 25px 8px 15px;
    border-radius: 30px;
}
.areaItem:before {
    content: "\f00d";
    font: normal normal normal 12px/1 FontAwesome;
}
.search-name-input {
    background-color: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    font-size: 14px;
    color: #484848;
    line-height: 1.2;
}
.dropbtn {
    font-size: 13px;
    cursor: pointer;
}
.btn.dropdown-toggle {
    margin-bottom: 0!important;
}
.textfield-search__dropdown-item {
    font-size: 15px;
}
.search-btn {
    background-color: #3e4c66;
    border: 1px solid #3e4c66;
    -webkit-box-shadow: 0px 1px 4px 0px rgba(29, 41, 62, 0.3);
    -moz-box-shadow: 0px 1px 4px 0px rgba(29, 41, 62, 0.3);
    -o-box-shadow: 0px 1px 4px 0px rgba(29, 41, 62, 0.3);
    box-shadow: 0px 1px 4px 0px rgba(29, 41, 62, 0.3);
    transition: all .25s ease-in-out;
    color: white;
}
.search-btn:not([disabled]):hover {
    background: var(--main-color);
    border-color: var(--main-color);
}
</style>
