const axios = require("axios").default

const apiInstance = axios.create({
    baseURL: "/api/"
});

export async function apiGet(url,query = {}) {
    return (await apiInstance.get(url,{
        params: query
    })).data;
}
